import { useState } from "react";
import axios from "axios";
import Form from "./Form";
import "../../../App.css";
import saved from "../../../assets/saved.png";
import { AiFillEdit } from "react-icons/ai";
import Saved from "./Saved";
import { BsHourglassSplit } from "react-icons/bs";

const Grid2 = (props) => {
  const userEmail = localStorage.getItem("currentUserEmail");
  const [creditCount, setCreditCount] = useState(0);
  const [openSave, setOpenSave] = useState(false);
  const [myContent, setMyContent] = useState([]);
  //console.log("PROPS IN GRID 2", props);
  const creditsBalance = (email) => {
    //console.log("GRID EMAIL", email);
    axios
      .post(
        "https://backendnew-production.up.railway.app/api/v1/credit-balance",
        {
          email: email,
        }
      )
      .then((res) => {
        // //console.log("Credit Balance Response", res.data);
        setCreditCount(res.data.creditBalance);
        localStorage.setItem("creditsBalance", creditCount);
        // //console.log("Credit Count", res.data.creditBalance);
      })
      .catch((err) => {
        //console.log(err);
      });
    return creditCount;
  };

  function handleSaved() {
    setOpenSave(true);
    axios
      .get(
        `https://backendnew-production.up.railway.app/api/v1/all-content?email=${userEmail}`
      )
      .then((res) => {
        //console.log("SAVED RESPONSE", res.data.contents);
        setMyContent(res.data.contents);
      })
      .catch((err) => {
        //console.log("SAVED ERROR", err);
      });
  }

  function handleCreateMore() {
    setOpenSave(false);
  }
  return (
    <div className="col-span-3 md:col-span-4 rounded-[8px] ml-[15px] grid-1 h-full  mr-[15px] mb-10 relative p-2 md:ml-[25px] md:mr-[34px]  flex flex-col bg-white">
      <div className="mt-[19px] mb-[20px]  text-center flex flex-row justify-between">
        <h2 className="cabin cabin-700 text-[#08652B]  pl-[31px] text-[18px]">
          Playground
        </h2>
        <div className="flex flex-row justify-self-end">
          {creditsBalance(userEmail) === 0 ? (
            <p className="mr-4">0/10 Free Credits Completed</p>
          ) : (
            <p className="cabin cabin-700 text-[#08652B]  pl-[31px] text-[16px] mr-[8px] pt-[5px]">
              {creditCount} Credits Left
            </p>
          )}
          {openSave ? (
            <button
              className="mr-[47px] bg-[#E0EEC6] px-[20px] rounded-[10px] text-[12px] cabin cabin-700 text-[#243E36] h-[29px] my-auto"
              onClick={handleCreateMore}
            >
              Continue Creating
              <AiFillEdit className="inline text-[20px]" />
            </button>
          ) : (
            <button
              className="mr-[47px] bg-[#E0EEC6] px-[20px] rounded-[10px] text-[12px] cabin cabin-700 text-[#243E36] h-[29px] my-auto"
              onClick={handleSaved}
            >
              My Contents
              <img src={saved} alt="" className="inline ml-[10px] w-[16px]" />
            </button>
          )}
        </div>
      </div>
      <hr className="bg-[#1B348D] h-[2px]" />

      {openSave ? (
        <Saved myContent={myContent} />
      ) : props.labelData === "CS" ? (
        <div className="flex flex-row justify-center items-center">
          <h1 className="text-center text-[24px] cabin cabin-600 mt-[350px]">
            Coming Soon <BsHourglassSplit className="inline" />
          </h1>
        </div>
      ) : (
        <Form
          dynamicInput={props.labelData}
          inputNumbers={props.inputNumbers}
          formHeading={props.formHeading}
          setCreditCount={setCreditCount}
        />
      )}
    </div>
  );
};

export default Grid2;
