import React, { Fragment } from 'react'
import PricingNav from '../Navs/PricingNav'
import '../../App.css'
import Card from './Card'
import Faq from './Faq'

const Pricing = () => {
    return (
        <Fragment>
            <PricingNav />
            <div>
                <h1 className='cabin-700 text-[#08652B] text-[40px] text-center mt-[20px]'>Our <span className='text-[#0DB64D]'>Pricing</span> Plan</h1>
                <h3 className='cabin-500 text-center'>Below are the prices that we could provide with <br />
                    services and maintanance of each package</h3>
            </div>
            <div className="flex flex-row justify-center mb-[100px]">
                <Card />
            </div>
            <hr className='mx-[5%]' />
            <div className='mx-[100px] lg:mx-[300px] mb-[64px]'>
                <h1 className='text-left cabin-600 text-[30px]'>FAQ's</h1>
                <Faq />
            </div>
        </Fragment>
    )
}

export default Pricing