import React, { Fragment } from "react";
import HeroCard from "./HeroCard";
import "../../App.css";

const HeroSection2 = () => {
  return (
    <Fragment>
      <div className="bg-[#2ADF64] pb-[38px]">
        <h1 className="text-center cabin font-[700] text-[50px] text-white">
          AI For Everyone
        </h1>
        <h3 className="text-center cabin font-[600] text-[22px]">
          Our AI models can create standard SOPs, LORs and other academic
          content in Minutes! <br />
          100% <span className="text-white">Plagiarism Free and Unique</span>
        </h3>
        <div className="grid grid-cols-2 gap-4 place-content-center mx-[179px] mt-[57px] ">
          <HeroCard />
        </div>
      </div>
    </Fragment>
  );
};

export default HeroSection2;
