import React from "react";
import Tools from "./Tools";
import "../../../App.css";
import toolbar from "../../../assets/toolbar.png";

const Grid1 = (props) => {
  const lowerPropsHandler = (short, inputs, heading, dropList) => {
    props.getProps(short, inputs, heading, dropList); //sending the specific list value to PlayGroundMain.js
  };

  return (
    <div className="col-span-2 md:col-span-1 rounded-[8px] ml-[17px] mr-[25px] grid-1 h-full relative  bg-white">
      <div className=" sticky top-10">
        <div className="mt-[19px] ml-[27px] mb-[20px] ">
          <img
            src={toolbar}
            className="inline w-[12px] h-[12px]"
            alt="toolbar"
          />
          <h3 className="inline cabin cabin-700 text-[#08652B] text-[18px] ml-[7px]">
            Toolbar
          </h3>
        </div>
        <hr className="bg-[#1B348D] h-[2px] mb-[22px]" />
        <div className="">
          <Tools getProps={lowerPropsHandler} />
        </div>
      </div>
    </div>
  );
};

export default Grid1;
