import { Link, useHistory, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Axios from "axios";
import HeroSection2 from "./HeroSection2";
import "../../App.css";
import centreLogo from "../../assets/arrow.png";
import polygon3 from "../../assets/polygon-3.png";
import polygon5 from "../../assets/polygon-5.png";
import googleLogo from "../../assets/google.png";
import polygon6 from "../../assets/polygon-6.png";
import polygon4 from "../../assets/polygon-4.png";
// import dotenv from 'dotenv';
// dotenv.config();
// const client_id = process.env.GOOGLE_CLIENT_ID;

const Hero = () => {
  const [tokenClient, setTokenClient] = useState({});

  const navigate = useNavigate();

  const googleSignHandler = () => {
    tokenClient.requestAccessToken();
  };

  useEffect(() => {
    const google = window.google; //getting global google variable. It is declared in HTML script
    //console.log("GOOGLE ACCOUNT", google);
    google.accounts.id.initialize({
      client_id:
        "605532017840-qti13sttq9ompnqeunu3bad4fupcrg3l.apps.googleusercontent.com",
    });
    //to get the access token for jwt signup
    setTokenClient(
      google.accounts.oauth2.initTokenClient({
        client_id:
          "605532017840-qti13sttq9ompnqeunu3bad4fupcrg3l.apps.googleusercontent.com",
        scope: "https://www.googleapis.com/auth/userinfo.email",
        callback: (tokenResponse) => {
          //console.log(tokenResponse.access_token);
          if (tokenResponse && tokenResponse.access_token) {
            const googleAccessToken = tokenResponse.access_token;
            Axios.post(
              "https://backendnew-production.up.railway.app/api/v1/signup",
              {
                googleAccessToken,
              }
            )
              .then((result) => {
                //console.log(result);
                navigate("/playground");
              })
              .catch((err) => {
                //console.log(err);
                // setError(err.response.message);
              });
          }
        },
      })
    );
    google.accounts.id.prompt();
  }, [navigate]);

  return (
    <div className="mt-[82px]">
      <div>
        <h3 className="text-center cabin font-[600] text-[25px] text-[#08652B]">
          Meet writopanda.ai,
        </h3>
        <h1 className="text-center mt-[24px] cabin font-[700] text-[55px]">
          From
        </h1>
        <div className="flex flex-row relative justify-center">
          <h1 className="pb-[100px] ml-[100px] text-[70px] font-[700] text-center text-[#2adf64]">
            Idea
          </h1>
          <img src={centreLogo} alt="center logo" className="arrow px-[50px]" />
          <h1 className="pt-[100px] text-[70px] font-[700] text-center text-[#2adf64]">
            Content
          </h1>
        </div>
      </div>
      <div className="polygon1 md:left-[58px] box-border absolute w-[38.04px] h-[37.39px] left-[-21.33px] top-[190.49px] border-[2.2434px] border-[#252525] rotate-[-30.2deg]"></div>
      <div className="polygon2 md:right-[260px] box-border absolute w-[16.64px] h-[16.64px] right-[24px] top-[196.73px] border-2 border-[#252525] rotate-[110.14deg]"></div>
      <div className="polygon3 md:left-[260px] box-border absolute w-[31.82px] h-[31.82px] left-[38px] top-[442.06px] rotate-[-77.5deg]">
        <img src={polygon3} className="w-[31px] h-[31px]" alt="Polygons" />
      </div>
      <div className="polygon4 box-border absolute w-[30.54px] h-[30.54px] right-0 top-[426.69px]">
        <img src={polygon4} className="ml-[14px]" alt="Polygons" />
      </div>
      <div className="polygon5 hidden md:block box-border absolute w-[30.54px] h-[30.54px] top-[426.69px] lef-0">
        <img src={polygon5} alt="Polygons" />
      </div>
      <div className="polygon6 hidden md:block box-border absolute w-[40px] h-[40px] right-[152px] top-[582.75px] rotate-[-120.97deg]">
        <img src={polygon6} alt="Polygons" />
      </div>
      <h1 className="text-center cabin font-[700] text-[55px]">easily.</h1>
      <p className="text-center mt-[24px] cabin font-[500] text-[22px]">
        Your virtual writing assistant helping in creating your marketing
        content in seconds!
      </p>
      <div className="footer relative mt-[32px]">
        <div className="md:hidden mobilefooterCircle box-border h-[50vh] w-full bg-[#41d2f7]"></div>
        <div className="hidden md:block desktopfooterCircle box-border h-[41.2vh] w-full bg-[#2ADF64]"></div>
        <p className="text-center absolute top-0 form mt-[24px] cabin font-[500] text-[22px] pt-[42px]">
          <span className="text-white">Get your</span> free account{" "}
          <span className="text-white">right away!</span>
        </p>
        <div className="absolute form hidden top-[100px] md:flex md:flex-row mt-[39px]">
          <div className="google bg-[#285238] text-white rounded-[20px] px-[10px] h-[41px]  mr-[15px]">
            <img
              src={googleLogo}
              className="inline my-[5px] mr-2 w-[30px] h-[31px]"
              alt="google logo"
            />
            {/* <button onClick={googleSignHandler} className="my-auto">
              Sign up with google
            </button> */}
            <button className="my-auto" onClick={googleSignHandler}>
              Sign up with google
            </button>
          </div>
          <div className="started bg-[#285238] text-white rounded-[20px] px-[30px] py-[5px] h-[41px] ml-[15px]">
            <Link to="/user/signup">Get Started</Link>
          </div>
        </div>
      </div>
      <HeroSection2 />
    </div>
  );
};

export default Hero;
