import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Routes,
  useLocation,
} from "react-router-dom";
import { useNavigate } from "react-router-dom";
function Home({ user }) {
  const nav = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.size > 0) {
      const q = queryParams.get("page");
      nav(q);
    } else {
      //console.log("homei");
      nav("/home");
    }
  }, []);

  // //console.log("QQRY", queryParams.get("page"));
  return <div>NO PAGE HERE</div>;
  // Rest of the component logic
}

export default Home;
