import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Routes,
} from "react-router-dom";
import { useLocation } from "react-router-dom";
import Hero from "./components/Landing Page/Hero";
import Navbar from "./components/Navs/Navbar";
import "./App.css";
import Auth from "./components/User/Auth";
import Playground from "./components/playground/Playground";
import Profile from "./components/User/Profile";
import Pricing from "./components/Pricing/Pricing";
import HomePage from "./pages/HomePage";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/Home";

function App() {
  const [user, setUser] = useState(localStorage.getItem("currentUser"));
  //console.log("Current user", user);
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Home user={user} />} />
        <Route
          path="/home"
          exact
          element={user ? <Navigate to="/playground" /> : <HomePage />}
        />

        <Route
          path="/user/signup"
          exact
          element={<SignUp setToken={setUser} />}
        />

        <Route
          path="/user/signin"
          exact
          element={
            user ? <Navigate to="/playground" /> : <SignIn setToken={setUser} />
          }
        />
        <Route
          path="/playground"
          exact
          element={user ? <Playground /> : <Navigate to="/user/signin" />}
        />

        <Route
          path="/user/profile"
          exact
          element={
            user ? (
              <Profile setToken={setUser} />
            ) : (
              <Navigate to="/user/signin" />
            )
          }
        />

        <Route path="/pricing" exact element={<Pricing />} />
        <Route path="*" element={<Home user={user} />} />
      </Routes>
    </Router>
  );
}

export default App;
