import React, { Fragment, useEffect, useState } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import googleAuth from "../../assets/authGoogle.png";
import pandaAuth from "../../assets/authPanda.png";
import passwordHide from "../../assets/hideP.png";
import passwordView from "../../assets/viewP.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Auth = ({ remember, login, setToken }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showP, setShowP] = useState(false);
  const [error, setError] = useState("");

  const [tokenClient, setTokenClient] = useState({});

  //show password toggler
  const handleClick = () => {
    setShowP(!showP);
  };

  //signup handler
  const signUpHandler = async (e) => {
    e.preventDefault();
    await Axios.post(
      "https://backendnew-production.up.railway.app/api/v1/signup",
      {
        username: "Sam",
        email,
        password,
        confirmPassword: "3432432",
      }
    )
      .then((response) => {
        const user = response.data;
        // const Id = user.user._id;
        //console.log("Signup Successful", response.data);
        // //console.log("User Id", Id);
        navigate("/user/signin");
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  //login handler
  const loginHandler = async (e) => {
    e.preventDefault();
    await Axios.post(
      "https://backendnew-production.up.railway.app/api/v1/signin",
      {
        username: "Sam",
        email,
        password,
        confirmPassword: "3432432",
      }
    )
      .then((response) => {
        const data = response.data;
        //console.log("Login Data", data);
        const currentUserId = data.userID;
        const accessToken = data.accessToken;
        document.cookie = `token=${accessToken};`;
        const token = document.cookie
          .split("; ")
          .filter((row) => row.startsWith("token="))
          .map((c) => c.split("=")[1])[0];

        if (token) {
          localStorage.setItem("currentUser", accessToken);
          localStorage.setItem("currentUserId", currentUserId);
          localStorage.setItem("currentUserEmail", email);
          setToken(accessToken);
          navigate("/playground");
        }
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        // //console.log(err.response.data.message);
        // setError(err.response.data.message);
      });
  };

  // google auth handler
  const googleSignHandler = () => {
    tokenClient.requestAccessToken();
  };

  //configuring google auth
  useEffect(() => {
    const google = window.google; //getting global google variable. It is declared in HTML script
    google.accounts.id.initialize({
      client_id:
        "605532017840-qti13sttq9ompnqeunu3bad4fupcrg3l.apps.googleusercontent.com",
    });
    //to get the access token for jwt signup
    setTokenClient(
      google.accounts.oauth2.initTokenClient({
        client_id:
          "605532017840-qti13sttq9ompnqeunu3bad4fupcrg3l.apps.googleusercontent.com",
        scope: "https://www.googleapis.com/auth/userinfo.email",
        callback: (tokenResponse) => {
          //console.log("tokenResponse.access_token", tokenResponse.access_token);
          if (tokenResponse && tokenResponse.access_token) {
            const googleAccessToken = tokenResponse.access_token;
            //console.log("Google Access Token", googleAccessToken);
            // //console.log('Google login', login)
            /* if (login) {
              try {
                Axios.get(
                  `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${tokenResponse.access_token}`,
                  {
                    headers: {
                      Authorization: `Bearer ${tokenResponse.access_token}`,
                      Accept: "application/json",
                    },
                  }
                )
                  .then((res) => {
                    //console.log("Google Login Response", res.data);
                    localStorage.setItem("currentUser", googleAccessToken);
                    localStorage.setItem("currentUserEmail", res.data.email);
                    setToken(googleAccessToken);
                    navigate("/playground");
                  })
                  .catch((err) => {
                    //console.log("Google Login Unsuccessful", err);
                  });
              } catch (error) {
                //console.log("GOOGLE SIGNIN UNSUCCESSFUL", error);
              }
            } else {
              //console.log(googleAccessToken);
              Axios.post("https://backendnew-production.up.railway.app/api/v1/signup", {
                googleAccessToken,
              })
                .then((result) => {
                  
                  ////console.log("HKC::signed", result);
                  navigate("/user/signin");
                })
                .catch((err) => {
                  //console.log("GOOGLE SIGNUP UNSUCCESSFUL", err);
                  // setError(err.response.message);
                });
            }*/
            //////////////////////new google login
            Axios.post(
              "https://backendnew-production.up.railway.app/api/v1/signup",
              {
                googleAccessToken,
              }
            )
              .then((result) => {
                //console.log("HKC::", result.data);
                return Axios.get(
                  `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${tokenResponse.access_token}`,
                  {
                    headers: {
                      Authorization: `Bearer ${tokenResponse.access_token}`,
                      Accept: "application/json",
                    },
                  }
                );
              })
              .then((res) => {
                /*console.log(
                  "Google Login Response",
                  res.data,
                  googleAccessToken
                );*/

                localStorage.setItem("currentUser", googleAccessToken);
                localStorage.setItem("currentUserEmail", res.data.email);
                setToken(googleAccessToken);
                navigate("/playground");
              })
              .catch((err) => {
                //console.log("GOOGLE SIGNUP/LOGIN UNSUCCESSFUL", err);
                // setError(err.response.message);
              });
          }
        },
      })
    );
    google.accounts.id.prompt();
  }, [navigate]);

  return (
    <div className="md:grid md:grid-cols-3  mt-[134px]">
      <div className="left col-span-2 ">
        {login === false ? (
          <h2 className="ml-[96px] text-[#1B348D] montserrat text-[30px] font-[600]">
            Create your Account!
          </h2>
        ) : (
          <Fragment>
            <h2 className="ml-[96px] text-[#1B348D] montserrat text-[30px] font-[600]">
              Welcome Back
            </h2>
            <h4 className="ml-[96px] montserrat font-[400] text-[18px] text-[#4E4E4E]">
              Login to continue
            </h4>
          </Fragment>
        )}
        <div className="ml-[96px] mt-[35px] mr-[370px]">
          <form>
            <div className="mb-5">
              <label
                htmlFor="email"
                className="block mb-2 text-[15px] text-black montserrat font-[600]"
              >
                Email
              </label>
              <input
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                name="email"
                id="email"
                className="email_input rounded-lg block w-full p-2.5 border-2"
                placeholder="Joymiller@gmail.com"
                required
              />
            </div>
            <div className="mb-5 relative">
              <label
                htmlFor="password"
                className="block mb-2 text-[15px] text-black montserrat font-[600]"
              >
                Password
              </label>
              <input
                onChange={(e) => setPassword(e.target.value)}
                type={!showP ? "password" : "text"}
                name="password"
                id="password"
                className="rounded-lg block w-full p-2.5 border-2 password_input"
                placeholder="*****"
                required
              />
              <span
                className="absolute top-[46px] right-5"
                onClick={handleClick}
              >
                <img
                  src={!showP ? passwordHide : passwordView}
                  className="w-[26px] h-[26px]"
                  alt="password logo"
                />
              </span>
            </div>
            {error && <p>{error}</p>}
            {remember && (
              <div className="flex mb-6 justify-between">
                <div className="flex items-center h-5">
                  <input
                    id="remember"
                    type="checkbox"
                    value=""
                    className=" h-4 border inline  rounded"
                  />
                  <label
                    htmlFor="remember"
                    className="ml-2 text-black text-[15px] montserrat font-[500]"
                  >
                    Remember me
                  </label>
                </div>
                <div className="forgetP text-[15px] montserrat font-[500]">
                  <p>Forgot your password?</p>
                </div>
              </div>
            )}
            {!login ? (
              <div>
                <button
                  type="submit"
                  className=" rounded-lg text-white w-full px-5 py-2.5 text-center bg-[#08652B]"
                  onClick={signUpHandler}
                >
                  {/* {login ? "Login" : "Get Started"} */}
                  Get Started
                </button>
                <ToastContainer />
              </div>
            ) : (
              <div>
                <button
                  type="submit"
                  className=" rounded-lg text-white w-full px-5 py-2.5 text-center bg-[#08652B]"
                  onClick={loginHandler}
                >
                  {/* {login ? "Login" : "Get Started"} */}
                  Login
                </button>
                <ToastContainer />
              </div>
            )}
            <div className="mt-[52px] mx-auto text-center">
              <p>Or</p>
            </div>
          </form>
          <div id="googleBtn"></div>
          <button
            className="google bg-black w-[50%] h-[44px] mx-auto mt-[44px] flex flex-row rounded-[10px]"
            onClick={googleSignHandler}
            no-referrer-when-downgrade="true"
          >
            <div className="bg-white h-[38px] my-auto ml-[2px] rounded-[10px]">
              <img
                src={googleAuth}
                className="w-[36px] h-[36px]"
                alt="auth google"
              />
            </div>
            <p className="text-white m-auto">
              {/*login ? "Log" : "Sign"*/}
              Log in with Google
            </p>
          </button>
        </div>
      </div>
      <div className="right">
        <img
          src={pandaAuth}
          alt="Panda Logo"
          className="w-[500px] h-[500px]"
          priority="true"
        />
      </div>
    </div>
  );
};

export default Auth;
