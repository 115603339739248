const dynamicFormHandler = (dynamicInput) => {
  let data;
  if (dynamicInput === "IP") {
    data = {
      inputLabel1: "Which course have you applied for?",
      inputLabel2: "Tell us about yourself!",
      placeholder1: "Eg. Masters in Computer Science",
      placeholder2:
        "Undergrad in COE from NSUT, Have always been intrigued with computers and working. Got hooked to the domain when I discovered what programming is. I have been an active member of the IOSD which is the tech society of our college. Deeply passionate about ML and AI.",
      task_type: "sop_builder_introduction",
    };
    return data;
  } else if (dynamicInput === "PE") {
    data = {
      inputLabel1:
        "Tell one relevant internships, jobs, or research experiences you had?",
      inputLabel2: "Experience 2",
      inputLabel3: "Experience 3",
      placeholder1:
        "E.g. Interned at XYZ Tech as a software developer. I gained valuable experience in writing code, debugging software issues, and providing technical support to clients. I participated in team meetings, code reviews, and other activities that helped me to improve the quality and efficiency of the software development process.",
      placeholder2:
        "E.g. I obtained practical expertise with Python, SQL, and different data analysis tools including Pandas, Numpy, and Scikit-learn while working as a data science intern at Spoofsense.ai. Data wrangling, data cleaning, and data visualisation chores were among my responsibilities. used machine learning algorithms like regression, decision trees, and random forests to build predictive models.",
      placeholder3:
        "E.g. I completed a second internship in data science at ScalerAI, where I developed machine learning models to increase the precision of the business's recommendation system. Employed collaborative filtering strategies including user-based and item-based recommendation systems to increase the system's accuracy. In order to find trends and client feedback.",
      task_type: "professional_experience",
    };
    return data;
  } else if (dynamicInput === "AB") {
    data = {
      inputLabel1: "What was your last degree?",
      inputLabel2:
        "What subjects or coursework have you excelled in or enjoyed?",
      placeholder1: "Eg. Undergraduate in Data Science",
      placeholder2:
        "E.g. Advanced algorithms, artificial intelligence, data structures, computer networks, and software engineering. Led a team to develop a machine learning model for predicting stock prices, presented a research paper on NLP at an international conference, graduated with a 3.9 GPA.",
      task_type: "academic_background",
    };
    return data;
  } else if (dynamicInput === "FP") {
    data = {
      inputLabel1: "What course have you applied for",
      inputLabel2: "What are you plans",
      placeholder1: "E.g. Post Graduate in Mechanical Engineering",
      placeholder2:
        "E.g. To complete the curriculum, learn the intricacies of the course. Work on Robotics and Automation, deploy new industry level techniques to improve the current state of robotics.",
      task_type: "future_plans",
    };
    return data;
  } else if (dynamicInput === "CP") {
    data = {
      inputLabel1: "Which course have you applied for?",
      inputLabel2:
        "What are your short-term and long-term goals after completing this course?",
      placeholder1: "E.g Masters in Psychology",
      placeholder2: "E.g Masters in Psychology",
      task_type: "closing_paragraph",
    };
    return data;
  } else if (dynamicInput === "LIP") {
    data = {
      inputLabel1: "What is the Recommender’s Profession?",
      inputLabel2: "Tell us about your relationship with the Recommender",
      placeholder1:
        "Eg. For the letter, I have requested a recommendation from the Head of Electrical Engineering Dept. at NSUT.",
      placeholder2:
        "E.g. Have been connected with Mr. ABC for the past 2 years. He has taught me Power Electronics and Apparatus. Have always been an attentive and ambitious student in his class.",
      task_type: "lor_introduction",
    };
    return data;
  } else if (dynamicInput === "MQ") {
    data = {
      inputLabel1: "Tell about your major qualities and achievements",
      placeholder1:
        "E.g I am an enthusiastic and driven individual. I have represented my college in numerous inter-college competitions and participated actively in debates and discussions.Additionally, I was a member of the academic club supervised by the recommender, where I contributed my best and participated in various activities",
      task_type: "lor_major_qualities",
    };
    return data;
  } else if (dynamicInput === "EA") {
    data = {
      inputLabel1: "Tell about your other qualities",
      placeholder1:
        "E.g. Throughout my academic career, I have maintained an active lifestyle and been involved in sports. I have been a strong athlete and have played volleyball and football at a competitive level. One of my proudest moments was representing my school at the Annual PQ School Sports fest, where our team placed first among 24 other teams. This experience taught me the importance of teamwork, dedication, and perseverance, which are skills that I have applied to other areas of my life.",
      task_type: "lor_extracurricular_qualities",
    };
    return data;
  } else if (dynamicInput === "CR") {
    data = {
      inputLabel1: "Closing Remarks",
      placeholder1:
        "I am a good problem solver and I am passionate about the domain I want to study in.",
      task_type: "lor_conclusion",
    };
    return data;
  } else {
    data = {
      inputLabel1: "Which course have you applied for?",
      inputLabel2: "Tell us about yourself!",
      placeholder1: "Eg. Masters in Computer Science",
      placeholder2:
        "Undergrad in COE from NSUT, Have always been intrigued with computers and working. Got hooked to the domain when I discovered what programming is. I have been an active member of the IOSD which is the tech society of our college. Deeply passionate about ML and AI.",
      task_type: "sop_builder_introduction",
    };
    return data;
  }
};

export default dynamicFormHandler;
