import tab1Logo from "../../../assets/tab1logo.png";
import tab2Logo from "../../../assets/tab2logo.png";
import tab3Logo from "../../../assets/tab3logo.png";
import tab4Logo from "../../../assets/tab4logo.png";
import tab5Logo from "../../../assets/tab5logo.png";

const toolData = [
  {
    id: 0,
    logo: tab1Logo,
    header: "SOP Builder",
    list: [
      {
        id: 0,
        listLink: "Introduction Paragraph",
        short: "IP",
        inputs: 2,
        task_type: "sop_builder_introduction",
      },
      {
        id: 1,
        listLink: "Academic Background",
        short: "AB",
        inputs: 2,
        task_type: "academic_background",
      },
      {
        id: 2,
        listLink: "Professional Experience",
        short: "PE",
        inputs: 3,
        task_type: "professional_experience",
      },
      {
        id: 3,
        listLink: "Future Plans",
        short: "FP",
        inputs: 2,
        task_type: "future_plans",
      },
      {
        id: 4,
        listLink: "Closing Paragraph",
        short: "CP",
        inputs: 2,
        task_type: "closing_paragraph",
      },
    ],
  },
  {
    id: 1,
    logo: tab2Logo,
    header: "LOR Builder",
    list: [
      {
        id: 0,
        listLink: "Introduction Paragraph",
        short: "LIP",
        inputs: 2,
        task_type: "lor_introduction",
      },
      {
        id: 1,
        listLink: "Major Qualities",
        short: "MQ",
        inputs: 1,
        task_type: "lor_major_qualities",
      },
      {
        id: 2,
        listLink: "Extracurricular Activities",
        short: "EA",
        inputs: 1,
        task_type: "lor_extracurricular_qualities",
      },
      {
        id: 3,
        listLink: "Closing Remarks",
        short: "CR",
        inputs: 1,
        task_type: "lor_conclusion",
      },
    ],
  },
  {
    id: 2,
    logo: tab3Logo,
    header: "Essays",
    list: [
      {
        id: 0,
        listLink: "Coming Soon",
        short: "CS",
      },
    ],
  },
  {
    id: 3,
    logo: tab4Logo,
    header: "Resume Builder",
    list: [
      {
        id: 0,
        listLink: "Coming Soon",
        short: "CS",
      },
    ],
  },
  // {
  //   id: 4,
  //   logo: tab5Logo,
  //   header: "Ad copy",
  //   list: [
  //     {
  //       id: 0,
  //       listLink: "Blog Ideas",
  //       short: "BI",
  //     },
  //     {
  //       id: 1,
  //       listLink: "Blog Outline",
  //       short: "BO",
  //     },
  //     {
  //       id: 2,
  //       listLink: "Blog Point to Paragraph",
  //       short: "BPP",
  //     },
  //   ],
  // },
];

export default toolData;
