import React, { useState } from "react";
import Grid1 from "./Grid1";
import Grid2 from "./Grid2";
import "../../../App.css";

const PlayGroundMain = () => {
  const [labels, setLabels] = useState("IP");
  const [formInputNumber, setFormInputNumber] = useState(2);
  const [title, setTitle] = useState("SOP Builder Introduction Paragraph");

  const handler = (value, inputs, heading, dropList) => {
    setLabels(value);
    setFormInputNumber(inputs);
    setTitle(`${dropList} ${heading}`);
    //console.log("PLAYGROUND INPUTS", inputs);
    //console.log("PLAYGROUND VALUE", value);
  };

  return (
    <div className="pt-[25px] bg-[#F1F7ED] nav absolute top-[64px] right-0 left-0">
      <div className="relative grid grid-cols-5 pb-[30px] h-full ">
        <Grid1 getProps={handler} />
        <Grid2
          labelData={labels}
          inputNumbers={formInputNumber}
          formHeading={title}
        />
      </div>
    </div>
  );
};

export default PlayGroundMain;
