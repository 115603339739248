import React, { Fragment } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "../Pricing/Modal";
import avatar from "../../assets/avatar.png";
import pandaLogo from "../../assets/panda.png";
import profilePicture from "../../assets/profilePicture.png";

const AuthorizedNav = ({ profile, pricing }) => {
  const [showModal, setShowModal] = useState(false);

  const modalOpenHandler = () => {
    setShowModal(true);
  };
  const modalCloseHandler = () => {
    setShowModal(false);
  };

  return (
    <Fragment>
      {showModal && <Modal onClose={modalCloseHandler} />}
      <nav
        className={`flex flex-row justify-between bg-[#F1F7ED] relative ${
          profile && "h-[220px] rounded-[17px] mx-[13px] mt-[14px]"
        }`}
      >
        <div className="left flex flex-row p-2">
          <img
            src={pandaLogo}
            className={`${
              !profile
                ? "md:h-[32px] w-[41px] my-auto"
                : "h-[32px] w-[41px] mt-1"
            }`}
            alt="writopanda logo"
          />
          <h3
            className={`cabin cabin-600 text-[24px] ${
              profile ? "" : "md:my-auto"
            } md:ml-2 text-[#243E36]`}
          >
            writopanda.ai
          </h3>
        </div>
        <div className={`hidden md:flex flex-row p-2`}>
          {profile ? (
            <Link
              to="/playground"
              className="text-[#243E36] py-[8px] px-[17px] rounded-[6px] homepage mr-[33px] mt-2 cabin-600 h-[35px] inline-flex items-center"
            >
              Playground
            </Link>
          ) : (
            <button
              className="bg-[#243E36] text-white py-[8px] px-[12px] rounded-[6px] homepage mr-[33px] my-auto h-[35px] cabin-600 inline-flex items-center"
              onClick={modalOpenHandler}
            >
              Buy Credits
            </button>
          )}
          {!profile ? (
            <Link to="/user/profile">
              <img
                src={avatar}
                className={`mr-[33px] w-[49px] h-[49px]`}
                alt="avatar"
              />
            </Link>
          ) : (
            <img
              src={avatar}
              className={`mr-[33px] w-[49px] h-[49px]`}
              alt="avatar"
            />
          )}
          {profile && (
            <button className="cabin cabin-600 text-black text-[17px] mt-[10px] my-auto mr-[59px]">
              {localStorage.getItem("currentUserEmail")}
            </button>
          )}
        </div>
        {profile && (
          <div className="mr-[33px] w-[200px] h-[200px] absolute bg-[#F2E4DB] rounded-full profile-photo border-[10px] border-white">
            <img
              src={profilePicture}
              className="mr-[33px] w-[200px] h-[200px]"
              alt="avatar"
            />
          </div>
        )}
      </nav>
    </Fragment>
  );
};

export default AuthorizedNav;
