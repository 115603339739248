import React, { useState } from "react";
import RTE from "./RTE";
import dynamicFormHandler from "./dynamicForm";
import "../../../App.css";
import pencil2 from "../../../assets/pencil2.png";
import axios from "axios";
import Spinner from "./Spinner";
import { BsSave2 } from "react-icons/bs";
import { FaCopy } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Form = ({ dynamicInput, inputNumbers, formHeading, setCreditCount }) => {
  const [showRTE, setShowRTE] = useState(false);
  const [name, setName] = useState("");
  const [description1, setDescription1] = useState("");
  const [description2, setDescription2] = useState("");
  const [output1, setOutput1] = useState("");
  const [output2, setOutput2] = useState("");
  const [output3, setOutput3] = useState("");
  const [loading, setLoading] = useState(false);
  const [contentList, setContentList] = useState([]);
  let balance = localStorage.getItem("creditsBalance");

  //console.log("name", name);

  //console.log("FORM HEADING", formHeading);

  const userEmail = localStorage.getItem("currentUserEmail");

  const toggleEditor = () => {
    setShowRTE(!showRTE);
  };

  const data = dynamicFormHandler(dynamicInput);
  //console.log("DYNAMIC INPUT", dynamicInput);

  const inputLabel1 = data.inputLabel1;
  const inputLabel2 = data.inputLabel2;
  const inputLabel3 = data.inputLabel3;
  const placeholder1 = data.placeholder1;
  const placeholder2 = data.placeholder2;
  const placeholder3 = data.placeholder3;
  const header = data.header;
  //console.log("FORM HEADER", header);

  function handleName(e) {
    setName(e.target.value);
  }

  function handleDescription1(e) {
    setDescription1(e.target.value);
  }

  function handleDescription2(e) {
    setDescription2(e.target.value);
  }

  function handleSubmit() {
    let list = contentList;
    //console.log("MORE LIST", list);
    if (userEmail && balance > 0) {
      setLoading(true);
      //console.log("CREATE NOW CALLED");
      axios
        .post("https://writopanda-backend-dev-eybqin3uyq-el.a.run.app/gpt3", {
          field_name: name,
          field_description: description1,
          field_description_2: description2,
          task_type: data.task_type,
        })
        .then((res) => {
          //console.log("GPT3 RESPONSE", res.data);
          handleCreditBalance(userEmail);
          setOutput1(res.data.gpt_output_1.split(":")[1]);
          setOutput2(res.data.gpt_output_2.split(":")[1]);
          setOutput3(res.data.gpt_output_3.split(":")[1]);
          // setLoading(false);
          const contentObj = [
            {
              title: formHeading,
              description: res.data.gpt_output_1.split(":")[1],
            },
            {
              title: formHeading,
              description: res.data.gpt_output_2.split(":")[1],
            },
            {
              title: formHeading,
              description: res.data.gpt_output_3.split(":")[1],
            },
          ];
          contentObj.map((item) => {
            list.push(item);
          });
          //console.log("MORE LIST AFTER API", list);
          setContentList(list);
          //console.log("CONTENT LIST", contentList);
          setLoading(false);
          // setName("");
          // setDescription1("");
          // setDescription2("");
        })
        .catch((err) => {
          //console.log("GPT3 ERROR", err);
        });
    } else {
      alert("Please purchase credits to continue");
    }
  }

  function handleCreate() {
    setOutput1("");
    setOutput2("");
    setOutput3("");
    let list = [];
    if (userEmail && balance > 0) {
      if (name === "" || description1 === "") {
        alert("Please fill out all fields");
      } else {
        setLoading(true);
        //console.log("CREATE NOW CALLED");
        axios
          .post("https://writopanda-backend-dev-eybqin3uyq-el.a.run.app/gpt3", {
            field_name: name,
            field_description: description1,
            field_description_2: description2,
            task_type: data.task_type,
          })
          .then((res) => {
            //console.log("GPT3 RESPONSE", res.data);
            handleCreditBalance(userEmail);
            setOutput1(res.data.gpt_output_1.split(":")[1]);
            setOutput2(res.data.gpt_output_2.split(":")[1]);
            setOutput3(res.data.gpt_output_3.split(":")[1]);
            // setLoading(false);
            const contentObj = [
              {
                title: formHeading,
                description: res.data.gpt_output_1.split(":")[1],
              },
              {
                title: formHeading,
                description: res.data.gpt_output_2.split(":")[1],
              },
              {
                title: formHeading,
                description: res.data.gpt_output_3.split(":")[1],
              },
            ];
            contentObj.map((item) => {
              list.push(item);
            });
            //console.log("LIST NEW", list);
            setContentList(list);
            //console.log("CONTENT LIST", contentList);
            //console.log("OUTPUT 1", output1);
            setLoading(false);
          })
          .catch((err) => {
            //console.log("GPT3 ERROR", err);
          });
      }
    } else {
      alert("Please purchase credits to continue");
    }
  }

  function handleCreditBalance(email) {
    axios
      .post(
        "https://backendnew-production.up.railway.app/api/v1/update-credits",
        {
          email,
        }
      )
      .then((res) => {
        //console.log("UPDATE CREDITS RESPONSE", res);
        setCreditCount(res.data.creditBalance);
        balance = res.data.creditBalance;
      })
      .catch((err) => {
        //console.log("UPDATE CREDITS ERROR", err);
      });
  }

  function handleSave(content) {
    if (content.description !== "") {
      axios
        .post("https://backendnew-production.up.railway.app/api/v1/create", {
          title: content.title,
          description: content.description,
          save: true,
          email: userEmail,
        })
        .then((res) => {
          //console.log("SAVED CONTENT RESPONSE", res.data);
          toast.success("Content saved successfully!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch((err) => {
          //console.log("SAVED CONTENT ERROR", err);
          toast.error("Couldn't save content!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  }

  function handleCopy(copiedText) {
    navigator.clipboard.writeText(copiedText);
    toast.success("Successfully copied to clipboard!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  return (
    <div className="grid relative grid-cols-3   h-full p-4 ">
      <div
        className={`${
          showRTE ? "col-span-2" : "col-span-3"
        } relative h-full flex flex-col`}
      >
        <h3 className="text-center text-[18px] cabin cabin-600 mt-[32px]">
          {`${
            formHeading ? formHeading : "SOP Builder Introduction Paragraph"
          }`}
        </h3>

        <div className="flex flex-col  mt-[20px]  h-full w-full">
          <div className="mx-auto  p-2 w-full items-center flex flex-col">
            <div className="mb-6 w-full max-w-[1000px]  flex flex-col">
              <label
                htmlFor="text"
                className="block mb-2 cabin cabin-600 text-[14px]"
              >
                {inputLabel1}
              </label>
              <input
                type="text"
                id="text"
                placeholder={placeholder1}
                value={name}
                className=" border-2 w-full  hover:border-[#08652B] rounded-lg max-w-[1000px] blocks p-2.5 "
                required
                onChange={handleName}
              />
            </div>
            {inputNumbers >= 2 && (
              <div className="mb-6 w-full max-w-[1000px]  flex flex-col">
                <label
                  htmlFor="message"
                  className="block mb-2 cabin cabin-600 text-[14px]"
                >
                  {inputLabel2}
                </label>
                <textarea
                  id="message"
                  rows="4"
                  placeholder={placeholder2}
                  value={description1}
                  className="border-2 w-full  hover:border-[#08652B] rounded-lg max-w-[1000px] blocks p-2.5  "
                  //placeholder="e.g. Write about yourself and your past experiences to build an introduction"
                  onChange={handleDescription1}
                  required
                ></textarea>
              </div>
            )}
            {inputNumbers === 3 && (
              <div className="mb-6 w-full max-w-[1000px]  flex flex-col">
                <label
                  htmlFor="message"
                  className="block mb-2 cabin cabin-600 text-[14px]"
                >
                  {inputLabel3}
                </label>
                <textarea
                  id="message"
                  rows="2"
                  placeholder={placeholder3}
                  value={description2}
                  className="border-2 w-full  hover:border-[#08652B] rounded-lg max-w-[1000px] blocks p-2.5 "
                  // placeholder="e.g. Write about yourself and your past experiences to build an introduction"
                  onChange={handleDescription2}
                ></textarea>
              </div>
            )}
            <button
              disabled={loading}
              className={`font-medium rounded-lg w-[178px] px-5 py-2.5 text-center bg-[#E0EEC6] text-[#243E36] text-[13px] cabin-700 mx-auto block hover:bg-[#bef264]`}
              // disabled={name === "" || description1 === ""}
              onClick={handleCreate}
            >
              Create Now
            </button>

            <div className="w-full bg-white max-w-[1000px] flex flex-col">
              {/* {loading && <Spinner />} */}
              {output1 !== "" && (
                <div className="mb-6  w-full max-w-[1000px]  flex flex-col">
                  {contentList.map((item) => {
                    return (
                      <div
                        contentEditable={true}
                        className="block p-2.5 rounded-lg border-2 w-full
             hover:border-[#08652B] focus:border-[#08652B]  my-[12px]"
                      >
                        <p>{item.description}</p>
                        <div className="flex flex-row-reverse">
                          <div>
                            <button
                              className="px-5 py-2.5 text-center bg-[#E0EEC6] text-[#243E36] text-[18px] cabin-700 block mr-[20px]"
                              onClick={() => handleCopy(item.description)}
                            >
                              <FaCopy />
                            </button>
                            <ToastContainer />
                          </div>
                          <div>
                            <button
                              className="px-5 mr-[10px] py-2.5 text-center bg-[#E0EEC6] text-[#243E36] text-[18px] cabin-700 block"
                              onClick={() => handleSave(item)}
                            >
                              <BsSave2 />
                            </button>
                            <ToastContainer />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <button
                    className="font-medium rounded-lg w-[178px] px-5 py-2.5 text-center bg-[#E0EEC6] text-[#243E36] text-[13px] cabin-700 mx-auto block"
                    onClick={handleSubmit}
                  >
                    Create More
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <button
          className="absolute right-[-38px] -rotate-90 top-[40%] bg-[#7CA982] text-white w-[99px] rounded-t-[10px]"
          onClick={toggleEditor}
        >
          <img
            src={pencil2}
            className="w-[13px] inline rotate-90"
            alt="toolbar"
          />
          <span className="px-2 text-[18px]">Editor</span>
        </button>
      </div>

      {loading && (
        <div className="h-full w-full bg-opacity-0 backdrop-blur-[1px] shadow-inner absolute bg-gray-500">
          <Spinner />
        </div>
      )}

      <div className={`${!showRTE ? "hidden" : "block"} h-[100%]`}>
        <RTE />
      </div>
    </div>
  );
};

export default Form;
/**
 *  <div className="flex flex-col justify-center mt-[20px] mb-[40px]">
          <div className="mx-auto">
            <div className="mb-6">
              <label
                htmlFor="text"
                className="block mb-2 cabin cabin-600 text-[14px]"
              >
                {inputLabel1}
              </label>
              <input
                type="text"
                id="text"
                value={name}
                className=" border-2 hover:border-[#08652B] hover:border-[#08652B]rounded-lg w-[700px] blocks p-2.5 w-[900px]"
                required
                onChange={handleName}
              />
            </div>
            {inputNumbers >= 2 && (
              <div className="mb-6">
                <label
                  htmlFor="message"
                  className="block mb-2 cabin cabin-600 text-[14px]"
                >
                  {inputLabel2}
                </label>
                <textarea
                  id="message"
                  rows="4"
                  value={description1}
                  className="block p-2.5 rounded-lg w-[700px] h-[120px] border-2  hover:border-[#08652B] focus:border-[#08652B] w-[900px]"
                  // placeholder="e.g. Write about yourself and your past experiences to build an introduction"
                  onChange={handleDescription1}
                  required
                ></textarea>
              </div>
            )}
            









            
            <div>
              {/* {loading && <Spinner />} }
              {output1 !== "" && (
                <div className="flex flex-col justify-center mt-[20px] mb-[40px]">
                  {contentList.map((item) => {
                    return (
                      <div
                        contentEditable={true}
                        className="block p-2.5 rounded-lg border-2 w-[700px]
             hover:border-[#08652B] focus:border-[#08652B] w-[900px] my-[12px]"
                      >
                        <p>{item.description}</p>
                        <div className="flex flex-row-reverse">
                          <div>
                            <button
                              className="px-5 py-2.5 text-center bg-[#E0EEC6] text-[#243E36] text-[18px] cabin-700 block mr-[20px]"
                              onClick={() => handleCopy(item.description)}
                            >
                              <FaCopy />
                            </button>
                            <ToastContainer />
                          </div>
                          <div>
                            <button
                              className="px-5 mr-[10px] py-2.5 text-center bg-[#E0EEC6] text-[#243E36] text-[18px] cabin-700 block"
                              onClick={() => handleSave(item)}
                            >
                              <BsSave2 />
                            </button>
                            <ToastContainer />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <button
                    className="font-medium rounded-lg w-[178px] px-5 py-2.5 text-center bg-[#E0EEC6] text-[#243E36] text-[13px] cabin-700 mx-auto block"
                    onClick={handleSubmit}
                  >
                    Create More
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
 */
