import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AuthorizedNav from "../Navs/PlayGroundNav";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import axios from "axios";
import Credits from "./Credits";

const Profile = ({ setToken }) => {
  const navigate = useNavigate();
  let percentage = 50;

  const userEmail = localStorage.getItem("currentUserEmail");
  //console.log(userEmail);

  const logoutHandler = () => {
    axios
      .get("https://backendnew-production.up.railway.app/api/v1/signout")
      .then((res) => {
        //console.log(res);
        localStorage.removeItem("currentUser");
        localStorage.removeItem("currentUserEmail");
        localStorage.removeItem("currentUserId");
        localStorage.removeItem("creditsBalance");
        setToken(null);
        navigate("/user/signin");
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  return (
    <Fragment>
      <AuthorizedNav profile={true} />
      <div className="block rounded-lg bg-white max-w-md mx-auto mt-[9%]">
        <h3 className="cabin-600 text-[22px] text-[#08652B] text-center my-auto">
          {localStorage.getItem("currentUserEmail")}
        </h3>
      </div>
      <div className="text-center text-[18px] cabin cabin-600 mt-[32px]">
        <p className="text-center">Credit Balance</p>
      </div>
      <div
        style={{ width: 90, height: 90 }}
        className="mx-auto py-3 mb-[25px] text-center mt-[25px] text-[30px] text-[#08652B] font-bold"
      >
        <Credits userEmail={userEmail} />
      </div>
      <div className="grid grid-cols-2 gap-4 mx-auto pb-[60px]">
        <button
          className="justify-self-end cabin-600 text-[18px] text-black my-auto w-[127px] h-[48px] border-2 rounded-[9px] border-[#7CA982] shadow-md"
          onClick={logoutHandler}
        >
          Logout
        </button>
        <Link
          to="/pricing"
          className="justify-self-start h-[48px] text-[15px] bg-[#08652B] text-white rounded-lg w-[140px] px-[31px] py-[13px] shadow-md"
        >
          Buy Credits
        </Link>
      </div>
    </Fragment>
  );
};

export default Profile;
