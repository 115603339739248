import React from "react";
import Navbar from "../components/Navs/Navbar";
import Hero from "../components/Landing Page/Hero";

const HomePage = () => {
  return (
    <>
      <Navbar authState="Signup" />
      <Hero />
    </>
  );
};

export default HomePage;
