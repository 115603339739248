import React from "react";
import Navbar from "../components/Navs/Navbar";
import Auth from "../components/User/Auth";

const SignIn = ({ setToken }) => {
  return (
    <>
      <Navbar authState="Signin" />
      <Auth remember={true} login={true} setToken={setToken} />
    </>
  );
};

export default SignIn;
