import React, { Fragment } from "react";
import pricingData from "./pricing_data";

import "../../App.css";

const Card = () => {
  const card = pricingData.map((pd) => {
    let marginTop;
    if (pd.id === 0 || pd.id === 1) {
      marginTop = "mt-[65px]";
    }
    return (
      <div
        className={`pricing_card  p-4 ${
          pd.premium
            ? "bg-[#08652B] text-white w-[400px] mt-[30px] h-[530px]"
            : "bg-[#F4F4F4] max-w-sm h-[450px] mt-[71px]"
        } rounded-[24px] border-white border-8  mx-[30px] text-center`}
        key={pd.id}
      >
        <div className="my-auto">
          {pd.premium && (
            <div className="flex flex-row justify-end">
              <h3 className="bg-[#0DB64D] text-white py-[8px] px-[12px] rounded-[24px]">
                POPULAR
              </h3>
            </div>
          )}
          <h5 className=" cabin-600 text-[35px] text-center">{pd.header}</h5>
          <div className="flex items-baseline text-black justify-center">
            <span
              className={`text-[25px] cabin-600 ${pd.premium && "text-white"}`}
            >
              $
            </span>
            <span
              className={`text-[25px] cabin-700 tracking-tight ${
                pd.premium && "text-white"
              }`}
            >
              {pd.price}
            </span>
            <span className="ml-1 text-xl font-normal text-gray-500 dark:text-gray-400">
              /month
            </span>
          </div>
          <ul className="space-y-2 my-7 mx-[20px] list-disc">
            {pd.list.map((l) => (
              <li className="flex space-x-3" key={l.id}>
                {l.listData}
              </li>
            ))}
          </ul>
          <button
            type="button"
            className={`${
              pd.premium ? "text-black bg-white" : "text-white bg-[#08652B] "
            } cabin-600 rounded-lg text-[14px] px-5 pt-3 mx-[25%] ${marginTop} inline-flex justify-center w-[140px] h-[50px]`}
          >
            Get Started
          </button>
        </div>
      </div>
    );
  });

  return <Fragment>{card}</Fragment>;
};

export default Card;
