import { Link } from "react-router-dom";
import React from "react";
import { GrMenu } from "react-icons/gr";
import "../../App.css";
import pandaLogo from "../../assets/panda.png";

const Navbar = ({ authState }) => {
  return (
    <nav className="flex flex-row justify-between mt-[47px] md:mt-[18px] ml-[12px]">
      <div className="left flex flex-row">
        <img src={pandaLogo} className="md:w-[50px]" alt="writopanda logo" />
        <h3 className="cabin font-[400] text-[25px] md:my-auto md:ml-2">
          Writopanda.ai
        </h3>
      </div>
      <div className="hidden md:block space-x-[37px] my-auto montserrat font-[500] text-[18px] cabin">
        <a href="https://www.writopanda.ai/">Home</a>
        <Link to="/pricing">Pricing</Link>
        {authState === "Signup" && <Link to="/user/signin">Login</Link>}
        {authState === "Signin" && <Link to="/user/signup">Signup</Link>}
      </div>
      <div className="right mr-[24px] text-[25px] md:hidden">
        <GrMenu />
      </div>
      <div className="hidden md:block">
        <Link
          to="/user/signup"
          className=" bg-[#6BD9A9] text-black mr-5 py-[10px] px-[17px] rounded-[20px] homepage"
        >
          Start your free trial
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
