import React, { Fragment } from "react";
import PlayGroundMain from "./PlayComps/PlayGroundMain";
import AuthorizedNav from "../Navs/PlayGroundNav";

const Playground = () => {
  return (
    <Fragment>
      <AuthorizedNav />
      <PlayGroundMain />
    </Fragment>
  );
};

export default Playground;
