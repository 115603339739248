import React from "react";
import axios from "axios";
import { useState } from "react";

const Credits = ({ userEmail }) => {
  //console.log("UserID Credits", userEmail);
  const [creditCount, setCreditCount] = useState(0);
  const creditsBalance = (email) => {
    //console.log("CREDIT BALANCE EMAIL", email);
    axios
      .post(
        "https://backendnew-production.up.railway.app/api/v1/credit-balance",
        {
          email: email,
        }
      )
      .then((res) => {
        //console.log("Credit Balance Response", res.data);
        setCreditCount(res.data.creditBalance);
        localStorage.setItem("creditsBalance", creditCount);
        //console.log("Credit Count", res.data.creditBalance);
      })
      .catch((err) => {
        //console.log(err);
      });
    return creditCount;
  };

  return (
    <div>
      <h1>{creditsBalance(userEmail)}</h1>
    </div>
  );
};

export default Credits;
