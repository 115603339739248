import React from "react";
import { FaCopy } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Saved = ({ myContent }) => {
  let contentTitle = [];
  myContent.map((item) => {
    if (!contentTitle.includes(item.title)) {
      contentTitle.push(item.title);
    }
  });
  function handleCopy(copiedText) {
    navigator.clipboard.writeText(copiedText);
    toast.success("Successfully copied to clipboard!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  return (
    <div>
      {myContent.length > 0 ? (
        <div className="container m-auto flex flex-col justify-self-center items-center">
          <h1 className="text-center text-[24px] cabin cabin-600 mt-[32px]">
            Saved Content
          </h1>
          {contentTitle.map((title) => {
            return (
              <div className="w-full max-w-[1000px]  flex flex-col">
                <h3 className="text-left text-[16px] cabin cabin-600 mt-[32px] pb-[3px]">
                  {title}
                </h3>
                <div
                  className="block p-2.5 w-full
                    my-[12px] "
                >
                  {myContent.map((item) => {
                    if (item.title === title) {
                      return (
                        <div
                          className="block p-2.5 rounded-lg border-2 w-full
                        hover:border-[#08652B] focus:border-[#08652B]  my-[12px] shadow-lg"
                        >
                          <h1 className="py-[10px] ">{item.description}</h1>
                          <div className="flex flex-row-reverse">
                            <button
                              className="px-5 py-2.5 text-center bg-[#E0EEC6] text-[#243E36] text-[18px] cabin-700 block"
                              onClick={() => handleCopy(item.description)}
                            >
                              <FaCopy />
                            </button>
                            <ToastContainer />
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <h1 className="text-center text-[24px] cabin cabin-600 mt-[32px]">
          You don't have any saved content
        </h1>
      )}
    </div>
  );
};

export default Saved;
