import React, { Fragment, useState } from "react";
import data from "./Data2";
const HeroCard = () => {
  const [hover, setHover] = useState(-1);

  const offHover = () => {
    setHover(-1);
  };

  const boxData = data.map((d) => (
    <div
      key={d.id}
      className="bg-[#2ADF64] hover:bg-[#0DB64D] p-[20px] border border-[#0DB64D] rounded-[21px] shadow-md services items-center"
      onMouseEnter={() => setHover(d.id)}
      onMouseLeave={offHover}
    >
      <div className="flex flex-row bg-white rounded-[20px] h-[73px] headline">
        <img
          src={d.logo}
          className="mx-2 my-auto w-[49px] h-[49px]"
          alt="card header logo"
        />
        <h1 className="text-[#08652B] mx-auto my-auto cabin text-[25px] font-[700]">
          {d.heading}
        </h1>
      </div>
      <ul
        className={`${
          hover === d.id ? "text-white" : "text-black"
        } my-[11px] text-center cabin font-[500] text-[16px]`}
      >
        {d.list.map((l) => (
          <li key={l.id}>{l.listData}</li>
        ))}
      </ul>
      <div className="text-center">
        <button
          className={` ${
            hover === d.id ? "bg-white text-black" : "bg-[#0DB64D] text-white"
          } px-[50px] py-[12px] rounded-[10px]  font-[600] text-[16px] cabin`}
        >
          Try it now
        </button>
      </div>
    </div>
  ));
  return <Fragment>{boxData}</Fragment>;
};

export default HeroCard;
