import React, { useState } from "react";
import { IconContext } from "react-icons";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { IoIosArrowDropupCircle } from "react-icons/io";

import toolData from "./toolData";

const Tools = (props) => {
  // const [dropList, setDropList] = useState();
  const dropList = ["SOP Builder", "LOR Builder", "Essays", "Resume Builder"];

  //console.log("DROP LIST", dropList);

  const handler = (short, inputs, heading, dropList) => {
    localStorage.setItem("openSave", false);
    props.getProps(short, inputs, heading, dropList); //sending the specific list value to Grid1.js
  };

  const toolList = toolData.map((td) => (
    <div
      key={td.id}
      id={td.id}
      className="hover:bg-[#F7F8FD] pt-[7px] ml-[17px] mr-[9px] mb-[20px] rounded-[15px] relative"
    >
      <div
        className="flex flex-row ml-2 hover:cursor-pointer"
        // onClick={() => setDropList(td.header)}
      >
        <img src={td.logo} className="w-[14x] h-[18px]" alt="toolbar logo" />
        <div>
          <h2 className="ml-[5px] cabin cabin-700 text-[14px] text-[#2C3D45]">
            {td.header}
          </h2>
          <ul
            className={`${
              dropList.includes(td.header) ? "block" : "hidden"
            } mt-7px `}
          >
            {td.list &&
              td.list.map((lst) => (
                <li
                  key={lst.id}
                  id={lst.id}
                  className="text-[12px] cabin cabin-500 ml-[7px] list-disc py-1"
                  onClick={() =>
                    handler(lst.short, lst.inputs, lst.listLink, td.header)
                  }
                >
                  <button className="text-[15px] font-thin hover:text-[#0f172a] hover:font-black focus:text-[#0f172a] focus:font-black">
                    {lst.listLink}
                  </button>
                  {/* {//console.log(
                    dropList === td.header && selected === lst.listLink
                      ? lst.listLink
                      : "nf"
                  )} */}
                </li>
              ))}
          </ul>
        </div>
        {dropList !== td.header ? (
          <IconContext.Provider
            value={{
              color: "#08652B",
              size: "1.5em",
            }}
          >
            <button className="absolute right-[10px]">
              <IoIosArrowDropdownCircle />
            </button>
          </IconContext.Provider>
        ) : (
          <IconContext.Provider
            value={{
              color: "#08652B",
              size: "1.5em",
            }}
          >
            <button className="absolute right-[10px]">
              <IoIosArrowDropupCircle />
            </button>
          </IconContext.Provider>
        )}
      </div>
    </div>
  ));

  return <div className="my-auto">{toolList}</div>;
};

export default Tools;
