import React from "react";
import { NavLink } from "react-router-dom";
import "../../App.css";
import pandaLogo from "../../assets/panda.png";

const PricingNav = () => {
  return (
    <nav className="flex flex-row justify-between bg-[#2ADF64]">
      <div className="left flex flex-row p-2">
        <img src={pandaLogo} className="md:w-[50px]" alt="writopanda logo" />
        <h3 className="cabin cabin-600 text-[24px] text-white">
          Writopanda.ai
        </h3>
      </div>
      <div className="hidden md:flex flex-row p-2 cabin-600 text-white text-[18px]">
        <a href="https://www.writopanda.ai/">Home</a>
        <NavLink to="/pricing" className="my-auto mx-[26px]">
          Pricing
        </NavLink>
        <NavLink to="/user/signin" className="my-auto mx-[26px]">
          Login
        </NavLink>
      </div>
    </nav>
  );
};

export default PricingNav;
