import React from "react";
import Navbar from "../components/Navs/Navbar";
import Auth from "../components/User/Auth";

const SignUp = ({ setToken }) => {
  return (
    <>
      <Navbar authState="Signup" />
      <Auth remember={false} login={false} setToken={setToken} />
    </>
  );
};

export default SignUp;
