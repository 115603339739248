const faqData = [
    {
        id: 0,
        question: 'What is writopanda?',
        answer1: 'an edited or emended copy of an original work. : a work',
        answer2: 'containing such text. the main body of printed or written matter on a page.',
    },
    {
        id: 1,
        question: 'How will writopanda help me in improving my SOP?',
        answer1: 'an edited or emended copy of an original work. : a work',
        answer2: 'containing such text. the main body of printed or written matter on a page.',
    },
    {
        id: 2,
        question: 'How does writopanda work?',
        answer1: 'an edited or emended copy of an original work. : a work',
        answer2: 'containing such text. the main body of printed or written matter on a page.',
    },
    {
        id: 3,
        question: 'Which software does writopanda use?',
        answer1: 'an edited or emended copy of an original work. : a work',
        answer2: 'containing such text. the main body of printed or written matter on a page.',
    }
]

export default faqData