const pricingData = [
    {
        id: 0,
        header: 'Basic',
        price: 200,
        list: [
            {
                id: 0,
                listData: 'Unlimited Runs'
            },
            {
                id: 1,
                listData: '24/7 Email Support'
            },
            {
                id: 2,
                listData: 'Access to all our Tools'
            },
            {
                id: 3,
                listData: 'Access to all the newest Tools'
            }
        ]
    },
    {
        id: 1,
        header: 'Premium',
        price: 300,
        premium: true,
        list: [
            {
                id: 0,
                listData: 'Unlimited Runs'
            },
            {
                id: 1,
                listData: 'Customized Tools on demand'
            },
            {
                id: 2,
                listData: '24/7 Email Support'
            },
            {
                id: 3,
                listData: 'Access to all our Tools'
            },
            {
                id: 4,
                listData: 'Access to the newest Tools'
            }
        ]
    },
    {
        id: 2,
        header: 'Ultra',
        price: 200,
        list: [
            {
                id: 0,
                listData: 'Unlimited Runs'
            },
            {
                id: 1,
                listData: 'Customized Tools on demand'
            },
            {
                id: 2,
                listData: '24/7 Email Support'
            },
            {
                id: 3,
                listData: 'Access to all our Tools'
            },
            {
                id: 4,
                listData: 'Access to the newest Tools'
            },
            {
                id: 5,
                listData: 'Access to our premium community'
            }
        ]
    },
]

export default pricingData