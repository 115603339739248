import sop from "../../assets/sop.png";
import lor from "../../assets/lor.png";
import da from "../../assets/da.png";
import ecom from "../../assets/ecom.png";

const data = [
  {
    id: 1,
    logo: sop,
    heading: "Statement Of Purpose",
    list: [
      {
        id: 1,
        listData: "SOP builder tools",
      },
      {
        id: 2,
        listData: "Blog Point to Paragraph",
      },
      {
        id: 3,
        listData: "Blog outline",
      },
    ],
  },
  {
    id: 2,
    logo: lor,
    heading: "Letter Of Recommendation",
    list: [
      {
        id: 1,
        listData: "Landing Page Text",
      },
      {
        id: 2,
        listData: "Meta Description",
      },
      {
        id: 3,
        listData: "Subheaders",
      },
    ],
  },
  {
    id: 3,
    logo: da,
    heading: "Digital Ads",
    list: [
      {
        id: 1,
        listData: "Facebook Ads",
      },
      {
        id: 2,
        listData: "LinkedIN Ads",
      },
      {
        id: 3,
        listData: "Google Ads",
      },
    ],
  },
  {
    id: 4,
    logo: ecom,
    heading: "E-commerce content",
    list: [
      {
        id: 1,
        listData: "Product Descriptions",
      },
      {
        id: 2,
        listData: "Catchy Headlines",
      },
      {
        id: 3,
        listData: "Amazon affiliate articles",
      },
    ],
  },
];

export default data;
