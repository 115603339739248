import React, { Fragment, useState } from 'react'
import faqData from './faqData'
import '../../App.css'
import { IconContext } from "react-icons";
import { FcPlus } from 'react-icons/fc'
import { RxCrossCircled } from 'react-icons/rx'

const Faq = () => {
    const [dropAns, setDropAns] = useState()
    const faq = faqData.map(fq => (
        <div className='text-left my-4 relative'>
            <h2 onClick={() => setDropAns(fq.question)} className='text-[19px] cabin-600 hover:cursor-pointer hover:text-[#08652B]'>{fq.question}</h2>
            <div className={`${dropAns === fq.question ? 'block' : 'hidden'} bg-[#F7F8FD] w-[600px] mt-3`}>
                <p>{fq.answer1}</p>
                <p>{fq.answer2}</p>
            </div>
            {dropAns === fq.question ? <IconContext.Provider
                value={{
                    color: "white",
                    size: "1.5em",
                    style: {
                        backgroundColor: '#2ADF64',
                        borderRadius: '50%'
                    }
                }}
            >
                <button className="absolute right-[30px] top-0">
                    <RxCrossCircled />
                </button>
            </IconContext.Provider> : <IconContext.Provider
                value={{
                    color: "#08652B",
                    size: "1.5em",

                }}
            >
                <button className="absolute right-[30px] top-0 bg-green text-white">
                    <FcPlus />
                </button>
            </IconContext.Provider>}
        </div>
    ))

    return (
        <Fragment>{faq}</Fragment>
    )
}

export default Faq