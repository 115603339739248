import React, { Fragment, useState } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import classes from "./Backdrop.module.css";
import "../../App.css";
import { BsCurrencyRupee } from "react-icons/bs";

const Backdrop = (props) => {
  return <div className={classes.backdrop} onClick={props.onClose} />;
};

const ModalOverlay = (props) => {
  return (
    <div className={classes.modal}>
      <div className={classes.content}>{props.children}</div>
    </div>
  );
};

const portalElement = document.getElementById("overlays");
//console.log("Portal Element", portalElement);

const Modal = (props) => {
  const [activeButton1, setActiveButton1] = useState(true);
  const [activeButton2, setActiveButton2] = useState(false);
  const [activeButton3, setActiveButton3] = useState(false);
  const [price, setPrice] = useState(299);
  const [stateOrder, setStateOrder] = useState(null);
  const [stateKey, setStateKey] = useState(null);

  let email = localStorage.getItem("currentUserEmail");
  let amountPlan = {
    29900: "basic",
    49900: "medium",
    89900: "premium",
  };
  let selectedPlan;
  let optionsAmount;

  const button1Handler = () => {
    setActiveButton1(true);
    setPrice(299);
    setActiveButton2(false);
    setActiveButton3(false);
  };
  const button2Handler = () => {
    setActiveButton1(false);
    setPrice(499);
    setActiveButton2(true);
    setActiveButton3(false);
  };
  const button3Handler = () => {
    setActiveButton1(false);
    setPrice(899);
    setActiveButton2(false);
    setActiveButton3(true);
  };

  const purchaseCredits = async (selectedPlan, email) => {
    //console.log("Selected Plan", selectedPlan);
    //console.log("User Email", email);
    await axios
      .post("https://backendnew-production.up.railway.app/api/v1/buy-credits", {
        selectedPlan,
        email,
      })
      .then((res) => {
        //console.log("Response", res);
      })
      .catch((err) => {
        //console.log(err);
      });
    window.location.href = "/playground";
  };

  const buyHandler = async (amount) => {
    try {
      const {
        data: { key },
      } = await axios.get(
        "https://backendnew-production.up.railway.app/payment/getkey"
      );
      const {
        data: { order },
      } = await axios.post(
        "https://backendnew-production.up.railway.app/api/v1/check-out",
        {
          amount,
        }
      );

      if (!order) {
        alert("Failed to load Razorpay, Please check your internet connection");
      }

      const options = {
        key,
        amount: order.amount,
        currency: "INR",
        name: "Writopanda",
        description: "Credits Payment",
        order_id: order.id,
        callback_url:
          "https://backendnew-production.up.railway.app/api/v1/verification",
        status: order.status,
        prefill: {
          //name: localStorage.getItem("currentUserEmail").split("@")[0],
          email: localStorage.getItem("currentUserEmail"),
          contact: "9999999999",
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#121212",
        },
      };

      sessionStorage.setItem("OrderStatus", JSON.stringify(order.status));
      //console.log("Payment Options", options);
      const razor = new window.Razorpay(options);
      razor.open();
      //console.log("After Razor", order);
    } catch (error) {
      //console.log("Error in Checkout", error);
    }
  };

  const modalJsx = (
    <Fragment>
      <div className="bg-[#DFECD6] scale-[1.03] h-[450px] rounded-[16px]">
        <h3 className="cabin-600 text-[24px]  pl-2 pt-2">
          Create non-stop content on the go!{" "}
        </h3>
        <p className="cabin-600 text-[18px] mb-[20px] pl-2 text-darkgreen">
          Specially curated plans for you
        </p>
        <hr className="border-[#243E36]" />
        <div className="flex flex-row justify-center mt-2 mx-[150px] py-1 bg-[#243E36] rounded-[25px]">
          <button
            className={`${
              activeButton1 ? "bg-[#F1F7ED] text-[#243E36]" : "text-[#F1F7ED]"
            } mx-3 rounded-[25px] px-4 py-[1px]`}
            onClick={button1Handler}
          >
            Basic
          </button>
          <button
            className={`${
              activeButton2 ? "bg-[#F1F7ED] text-[#243E36]" : "text-[#F1F7ED]"
            } mx-3 rounded-[25px] px-4 py-[1px]`}
            onClick={button2Handler}
          >
            Medium
          </button>
          <button
            className={`${
              activeButton3 ? "bg-[#F1F7ED] text-[#243E36]" : "text-[#F1F7ED]"
            } mx-3 rounded-[25px] px-4 py-[1px]`}
            onClick={button3Handler}
          >
            Pro
          </button>
        </div>

        <div className="bg-[#DFECD6] w-[85%] mt-[20px] h-[220px] rounded-[25px] mx-auto mb-[20px]">
          <h1 className="pl-[40px] pt-[15px] text-[18px] text-[#243E36] font-bold text-[22px]">
            {`${activeButton1 ? "Basic - 100 Credits" : ""} ${
              activeButton2 ? "Medium - 250 Credits" : ""
            } ${activeButton3 ? "Pro - 500 Credits" : ""}`}
          </h1>
          <div>
            <h2 className="text-[30px] text-[#08652B] font-bold text-center">
              {`Rs.${price}/-`}
            </h2>
          </div>

          <div className="pl-[50px]">
            <ul className="list-disc marker:text-[#304820] flex flex-col text-[18px]">
              <li>Unlimited Ideas</li>
              <li>In-built Paraphraser</li>
              <li>Grammar and Tone Check</li>
              <li>Plagiarism free Content</li>
            </ul>
          </div>
          <button
            className="absolute left-[197px] right-[192px] bottom-[40px] px-[30px] bg-[#304820] text-[#F1F7ED] h-[34px] cabin-600 rounded-[6px]"
            onClick={async () => {
              await buyHandler(price);
            }}
          >
            Upgrade Now
          </button>
        </div>
      </div>
    </Fragment>
  );
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <Backdrop onClose={props.onClose} />,
        portalElement
      )}
      {ReactDOM.createPortal(
        <ModalOverlay>
          {modalJsx}
          {/* <button onClick={props.onClose}>close</button> */}
        </ModalOverlay>,
        portalElement
      )}
    </Fragment>
  );
};

export default Modal;
